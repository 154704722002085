import { noop } from 'rxjs';
import { I4BCellType, I4BColumnConfiguration } from './configurations';
import { getDefaultColumnsByConcept } from './default-grids.definitions';
import {
  ArrayOfObjectColumn,
  ExpandedVariableColumn,
  I4BBasicColumn,
  I4BColumn,
  I4BDeviceStatusColumn,
  I4BSelectionColumn,
  IdColumn,
  NameColumn,
  XmqttDeviceButtonColumn
} from './definitions';
import { I4bCellConcept } from './enums';
import { HeaderType, I4BColumnHeader, I4BGrid, I4BGridOptions, XmqttDevicesGridData } from './models';
import { I4BColumnOptions } from './options';

export const DEFAULT_DEVICES_COLUMNS_FOR_XMQTT_DEVICES_VIEW: I4BColumn<I4BColumnHeader, I4BColumnConfiguration, I4BColumnOptions>[] = [
  new NameColumn(
    { displayName: 'NAME', type: HeaderType.ICON_AND_TEXT, icon: 'device' },
    {
      id: 'name',
      translationKey: 'MASTER_VIEW_ENGINE.TABLE.HEADER_NAME.DEVICE_NAME',
      concept: I4bCellConcept.DEVICES,
      width: 200,
      isDefault: true,
      cell: { type: I4BCellType.BASIC_LINK, event: { type: 'navigateToDevice' } }
    },
    { order: 1, pinned: 'left', width: 200 }
  ),
  new IdColumn(
    { displayName: 'IDENTIFIER' },
    {
      id: 'identifier',
      concept: I4bCellConcept.DEVICES,
      width: 170,
      isDefault: true,
      cell: { type: I4BCellType.BASIC_LINK, event: { type: 'navigateToDevice' } }
    },
    { order: 2 }
  ),
  new I4BBasicColumn(
    { displayName: 'FAMILY' },
    {
      id: 'type.family',
      translationKey: 'MASTER_VIEW_ENGINE.TABLE.HEADER_NAME.DEVICE_TYPE_FAMILY',
      cell: { type: I4BCellType.BASIC },
      isDefault: false,
      sortProperty: 'type.family',
      concept: I4bCellConcept.DEVICES,
      width: 130
    },
    {
      order: 3
    }
  ),
  new I4BBasicColumn(
    { displayName: 'MODEL' },
    {
      id: 'type.model',
      translationKey: 'MASTER_VIEW_ENGINE.TABLE.HEADER_NAME.DEVICE_TYPE_MODEL',
      cell: { type: I4BCellType.BASIC },
      isDefault: true,
      sortProperty: 'type.model',
      concept: I4bCellConcept.DEVICES,
      width: 130
    },
    {
      order: 4
    }
  ),
  new I4BBasicColumn(
    { displayName: 'FIRMWARE' },
    {
      id: 'type.firmware',
      translationKey: 'MASTER_VIEW_ENGINE.TABLE.HEADER_NAME.DEVICE_TYPE_FIRMWARE',
      cell: { type: I4BCellType.BASIC },
      isDefault: true,
      sortProperty: 'type.firmware',
      concept: I4bCellConcept.DEVICES,
      width: 130
    },
    {
      order: 5
    }
  ),
  new I4BDeviceStatusColumn({}, { id: 'status.name', isDefault: true, concept: I4bCellConcept.DEVICES }, { order: 6 }),
  new I4BBasicColumn(
    { displayName: 'LAST INCOMING CALL', type: HeaderType.BASIC },
    {
      translationKey: 'MASTER_VIEW_ENGINE.TABLE.HEADER_NAME.LAST_INCOMING_CALL',
      id: 'lastCallStatus.datetime',
      isDefault: true,
      concept: I4bCellConcept.DEVICES,
      cell: {
        type: I4BCellType.DATE
      }
    },
    {
      order: 7
    }
  ),
  new I4BBasicColumn(
    { displayName: 'ENDPOINT', type: HeaderType.BASIC },
    {
      id: 'endpoint',
      translationKey: 'MASTER_VIEW_ENGINE.TABLE.HEADER_NAME.DEVICE_ENDPOINT',
      isDefault: true,
      sortProperty: 'endpoint',
      cell: { type: I4BCellType.BASIC },
      concept: I4bCellConcept.DEVICES
    },
    { order: 8 }
  ),
  new I4BBasicColumn(
    { displayName: 'SUPPORT', type: HeaderType.BASIC },
    {
      id: 'support',
      translationKey: 'MASTER_VIEW_ENGINE.TABLE.HEADER_NAME.DEVICE_SUPPORT',
      isDefault: false,
      sortProperty: 'support',
      cell: { type: I4BCellType.BASIC },
      concept: I4bCellConcept.DEVICES
    },
    { order: 9 }
  ),
  new I4BBasicColumn(
    { displayName: 'INCOMING CONNECTOR NAME', type: HeaderType.BASIC },
    {
      translationKey: 'MASTER_VIEW_ENGINE.TABLE.HEADER_NAME.INCOMING_CONNECTOR_NAME',
      id: 'incomingConnector.name',
      isDefault: false,
      sortProperty: 'incomingConnector.name',
      concept: I4bCellConcept.DEVICES,
      width: 210,
      cell: {
        type: I4BCellType.BASIC
      }
    },
    {
      order: 10
    }
  ),
  new I4BBasicColumn(
    { displayName: 'INCOMING CONNECTOR ID', type: HeaderType.BASIC },
    {
      translationKey: 'MASTER_VIEW_ENGINE.TABLE.HEADER_NAME.INCOMING_CONNECTOR_ID',
      id: 'incomingConnector.id',
      isDefault: false,
      sortProperty: 'incomingConnector.id',
      concept: I4bCellConcept.DEVICES,
      width: 200,
      cell: {
        type: I4BCellType.BASIC
      }
    },
    {
      order: 11
    }
  ),
  new I4BBasicColumn(
    { displayName: 'OUTGOING CONNECTOR NAME', type: HeaderType.BASIC },
    {
      translationKey: 'MASTER_VIEW_ENGINE.TABLE.HEADER_NAME.OUTGOING_CONNECTOR_NAME',
      id: 'outgoingConnector.name',
      isDefault: false,
      sortProperty: 'outgoingConnector.name',
      concept: I4bCellConcept.DEVICES,
      width: 210,
      cell: {
        type: I4BCellType.BASIC
      }
    },
    {
      order: 12
    }
  ),
  new I4BBasicColumn(
    { displayName: 'OUTGOING CONNECTOR ID', type: HeaderType.BASIC },
    {
      translationKey: 'MASTER_VIEW_ENGINE.TABLE.HEADER_NAME.OUTGOING_CONNECTOR_ID',
      id: 'outgoingConnector.id',
      isDefault: false,
      sortProperty: 'outgoingConnector.id',
      concept: I4bCellConcept.DEVICES,
      width: 200,
      cell: {
        type: I4BCellType.OUTGOING_CONNECTOR
      }
    },
    {
      order: 13
    }
  ),
  new I4BBasicColumn(
    { displayName: 'ENTITY NAME', type: HeaderType.BASIC },
    {
      id: 'entity.name',
      translationKey: 'MASTER_VIEW_ENGINE.TABLE.HEADER_NAME.ENTITY_NAME',
      isDefault: false,
      sortProperty: 'entity.name',
      cell: { type: I4BCellType.BASIC },
      concept: I4bCellConcept.DEVICES
    },
    { order: 14 }
  ),
  new I4BBasicColumn(
    { displayName: 'LAST COMMAND STATUS' },
    {
      id: 'lastCommandStatus.name',
      concept: I4bCellConcept.DEVICES,
      isDefault: false,
      sortProperty: 'lastCommandStatus.name',
      translationKey: 'MASTER_VIEW_ENGINE.TABLE.HEADER_NAME.LAST_COMMAND_STATUS',
      cell: { type: I4BCellType.LAST_COMMAND_STATUS_CELL }
    },
    { order: 15 }
  ),
  new I4BBasicColumn(
    { displayName: 'LAST COMMAND DATE', type: HeaderType.BASIC },
    {
      id: 'lastCommandStatus.datetime',
      cell: { type: I4BCellType.DATE },
      translationKey: 'MASTER_VIEW_ENGINE.TABLE.HEADER_NAME.LAST_COMMAND_DATE',
      isDefault: false,
      concept: I4bCellConcept.DEVICES,
      width: 180
    },
    {
      order: 16
    }
  ),
  new I4BBasicColumn(
    { displayName: 'LAST COMMAND SENT' },
    {
      id: 'lastCommandStatus.command',
      translationKey: 'MASTER_VIEW_ENGINE.TABLE.HEADER_NAME.LAST_COMMAND_SENT',
      cell: { type: I4BCellType.BASIC },
      isDefault: false,
      concept: I4bCellConcept.DEVICES,
      width: 180
    },
    {
      order: 17
    }
  ),
  new I4BBasicColumn(
    { displayName: 'LAST COMMAND MESSAGE' },
    {
      id: 'lastCommandStatus.status',
      translationKey: 'MASTER_VIEW_ENGINE.TABLE.HEADER_NAME.LAST_COMMAND_MESSAGE',
      cell: { type: I4BCellType.BASIC },
      isDefault: false,
      concept: I4bCellConcept.DEVICES,
      width: 200
    },
    {
      order: 18
    }
  ),
  new I4BBasicColumn(
    { displayName: 'LAST COMMAND ERROR' },
    {
      id: 'lastCommandStatus.commandErrorReason',
      translationKey: 'MASTER_VIEW_ENGINE.TABLE.HEADER_NAME.LAST_COMMAND_ERROR',
      cell: { type: I4BCellType.BASIC },
      isDefault: false,
      concept: I4bCellConcept.DEVICES,
      width: 180
    },
    {
      order: 19
    }
  ),
  new ArrayOfObjectColumn(
    { displayName: 'FILES', type: HeaderType.BASIC },
    {
      id: 'files',
      translationKey: 'MASTER_VIEW_ENGINE.TABLE.HEADER_NAME.DEVICE_FILES',
      isDefault: false,
      sortProperty: 'files',
      width: 300,
      cell: {
        type: I4BCellType.ARRAY_OF_OBJECTS,
        options: {
          attributes: ['name'],
          expressions: [{ key: 'download', value: true }]
        }
      },
      concept: I4bCellConcept.DEVICES
    },
    { order: 20 }
  ),
  new I4BBasicColumn(
    { displayName: 'OPERATOR' },
    {
      id: 'communication.operator',
      translationKey: 'MASTER_VIEW_ENGINE.TABLE.HEADER_NAME.OPERATOR',
      cell: { type: I4BCellType.BASIC },
      isDefault: true,
      concept: I4bCellConcept.DEVICES,
      width: 150
    },
    {
      order: 21
    }
  ),
  new I4BBasicColumn(
    { displayName: 'ICCID', type: HeaderType.BASIC },
    {
      id: 'iccid',
      translationKey: 'MASTER_VIEW_ENGINE.TABLE.HEADER_NAME.DEVICE_ICCID',
      isDefault: true,
      sortProperty: 'iccid',
      cell: { type: I4BCellType.BASIC },
      concept: I4bCellConcept.DEVICES
    },
    { order: 22 }
  ),
  new I4BBasicColumn(
    { displayName: 'AFFILIATE', type: HeaderType.BASIC },
    {
      id: 'affiliate',
      translationKey: 'MASTER_VIEW_ENGINE.TABLE.HEADER_NAME.DEVICE_AFFILIATE',
      isDefault: true,
      sortProperty: 'affiliate',
      cell: { type: I4BCellType.BASIC },
      concept: I4bCellConcept.DEVICES
    },
    { order: 23 }
  )
];

export const DEFAULT_DIAGNOSTIC_VARIABLE_COLUMNS_FOR_XMQTT_DEVICE_VIEW: I4BColumn<I4BColumnHeader, I4BColumnConfiguration, I4BColumnOptions>[] = [
  new ExpandedVariableColumn(
    { displayName: 'BATTERY LEVEL' },
    {
      id: 'expandedVariables.bl.lastValue.value',
      isDefault: false,
      isConfigurable: true,
      sortProperty: 'expandedVariables.bl.lastValue.value',
      concept: I4bCellConcept.DIAGNOSTIC_VARIABLES,
      cell: {
        type: I4BCellType.VARIABLE_VALUE_CELL,
        options: {
          combineWithUnit: true
        }
      },
      width: 170
    },
    { order: 30, customId: 'expandedVariables.bl', sortable: true, isDisplayed: true }
  ),
  new ExpandedVariableColumn(
    { displayName: 'ENERGY MODE' },
    {
      id: 'expandedVariables.em.lastValue.value',
      isDefault: false,
      isConfigurable: true,
      sortProperty: 'expandedVariables.em.lastValue.value',
      concept: I4bCellConcept.DIAGNOSTIC_VARIABLES,
      cell: {
        type: I4BCellType.VARIABLE_VALUE_CELL,
        cellClassRules: {
          'bg-blue300': 'value === "FPM"',
          'bg-green200': 'value === "ECO1"',
          'bg-orange300': 'value === "ECO2"',
          'bg-red300': 'value === "PSM"'
        },
        options: {
          textAlign: 'center',
          translatedValues: {
            0: {
              value: 'FPM',
              color: '#FFFFFF',
              // backgroundColor: '#66ACEC',
              align: 'center'
            },
            1: {
              value: 'ECO1',
              color: '#333333',
              // backgroundColor: '#B1D7A5',
              align: 'center'
            },
            2: {
              value: 'ECO2',
              color: '#333333',
              // backgroundColor: '#F4BB66',
              align: 'center'
            },
            3: {
              value: 'PSM',
              color: '#FFFFFF',
              // backgroundColor: '#E76678',
              align: 'center'
            }
          }
        }
      },
      width: 175
    },
    { order: 31, customId: 'expandedVariables.em', sortable: true, isDisplayed: true }
  ),
  new ExpandedVariableColumn(
    { displayName: 'POWER SUPPLY', type: HeaderType.ICON_AND_TEXT, showHeaderName: false },
    {
      id: 'expandedVariables.pws.lastValue.value',
      isDefault: false,
      isConfigurable: true,
      sortProperty: 'expandedVariables.pws.lastValue.value',
      concept: I4bCellConcept.DIAGNOSTIC_VARIABLES,
      width: 120,
      cell: {
        type: I4BCellType.ICON,
        options: {
          textAlign: 'center',
          translatedValues: {
            0: {
              value: 'power-supply'
            }
          }
        }
      }
    },
    { order: 32, customId: 'expandedVariables.pws' }
  )
];

export class DefaultXMQTTDevicesGrid implements I4BGrid<I4BGridOptions, XmqttDevicesGridData> {
  masterview = 'xmqtt-devices';
  isDefault = false;
  isUserDefault = false;
  isAppDefault = false;
  name = null;
  userId = null;
  data: XmqttDevicesGridData = {
    response: {
      data: [],
      pagination: null
    }
  };
  gridOptions: I4BGridOptions = {
    pageSize: 100,
    multipleSelection: false,
    autoRefresh: { enabled: true, delay: 120 },
    gridSort: [{ colId: 'name', sort: 'asc', sortIndex: 2 }],
    buttonColumn: { enabled: true, className: new XmqttDeviceButtonColumn() },
    selectionColumn: { enabled: true, className: new I4BSelectionColumn() }
  };
  columns: I4BColumn<I4BColumnHeader, I4BColumnConfiguration, I4BColumnOptions>[] = [...getDefaultColumnsByConcept(this.masterview)];

  export = () => noop();
}
