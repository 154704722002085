import { I4BColumnConfiguration } from './configurations';
import { DefaultActiveAssetEventsPopupGrid } from './default-active-asset-events-popup-grid.definition';
import {
  DEFAULT_ASSETS_COLUMNS_FOR_ASSET_EVENTS_BY_ASSET_VIEW,
  DEFAULT_EVENTS_COLUMNS_FOR_ASSET_EVENTS_BY_ASSET_VIEW,
  DefaultAssetEventsByAssetGrid
} from './default-asset-events-by-asset-grid.definition';
import {
  DEFAULT_ASSETS_COLUMNS_FOR_ASSET_EVENTS_BY_PE_RULE_VIEW,
  DEFAULT_EVENTS_COLUMNS_FOR_ASSET_EVENTS_BY_PE_RULE_VIEW,
  DefaultAssetEventsByPERuleGrid
} from './default-asset-events-by-pe-rule-grid.definition';
import {
  DEFAULT_ASSETS_COLUMNS_FOR_ASSET_EVENTS_BY_SITE_VIEW,
  DEFAULT_EVENTS_COLUMNS_FOR_ASSET_EVENTS_BY_SITE_VIEW,
  DefaultAssetEventsBySiteGrid
} from './default-asset-events-by-site-grid.definition';
import {
  DEFAULT_ASSETS_COLUMNS_FOR_ASSET_EVENTS_BY_TOPIC_VIEW,
  DEFAULT_EVENTS_COLUMNS_FOR_ASSET_EVENTS_BY_TOPIC_VIEW,
  DefaultAssetEventsByTopicGrid
} from './default-asset-events-by-topic-grid.definition';
import {
  DEFAULT_ASSETS_COLUMNS_FOR_ASSET_EVENTS_VIEW,
  DEFAULT_DEVICES_COLUMNS_FOR_ASSETS_EVENTS_VIEW,
  DEFAULT_EVENTS_COLUMNS_FOR_ASSET_EVENTS_VIEW,
  DEFAULT_SITES_COLUMNS_FOR_ASSET_EVENTS_VIEW,
  DefaultAssetEventsGrid
} from './default-asset-events-grid.definition';

import {
  DEFAULT_ASSETS_COLUMNS_FOR_ASSETS_VIEW,
  DEFAULT_SITES_COLUMNS_FOR_ASSETS_VIEW,
  DEFAULT_TECHNICAL_COLUMNS_FOR_ASSETS_VIEW,
  DefaultAssetsGrid,
  FOLLOWED_VARIABLES_COLUMNS_FOR_ASSETS_VIEW,
  VARIABLES_GROUPED_COLUMNS_FOR_ASSETS_VIEW
} from './default-asset-grid.definition';
import { DEFAULT_ASSET_TEMPLATES_COLUMNS_FOR_ASSET_TEMPLATES_VIEW, DefaultAssetTemplatesGrid } from './default-asset-templates-grid.definition';
import { DEFAULT_CONNECTORS_COLUMNS_FOR_CONNECTORS_VIEW, DefaultConnectorsGrid } from './default-connectors-grid.definition';
import {
  DEFAULT_DEVICES_COLUMNS_FOR_DALIA_DEVICES_VIEW,
  DEFAULT_DIAGNOSTIC_VARIABLE_COLUMNS_FOR_DALIA_DEVICE_VIEW
} from './default-dalia-device-grid.definition';
import { DEFAULT_DEVICE_TEMPLATES_COLUMNS_FOR_DALIA_DEVICE_TEMPLATES_VIEW } from './default-dalia-device-templates-grid.definition';
import { DEFAULT_FIRMWARES_COLUMNS_FOR_DALIA_FIRMWARES_VIEW } from './default-dalia-firmwares-grid.definition';
import { DEFAULT_SENSORS_COLUMNS_FOR_DALIA_SENSORS_VIEW } from './default-dalia-sensors-grid.definition';
import {
  DEFAULT_DEVICES_COLUMNS_FOR_DEVICE_EVENTS_BY_DEVICE_VIEW,
  DEFAULT_EVENTS_COLUMNS_FOR_DEVICE_EVENTS_BY_DEVICE_VIEW,
  DefaultDeviceEventsByDeviceGrid
} from './default-device-events-by-device-grid.definition';
import {
  DEFAULT_DEVICES_COLUMNS_FOR_DEVICE_EVENTS_BY_PE_RULE_VIEW,
  DEFAULT_EVENTS_COLUMNS_FOR_DEVICE_EVENTS_BY_PE_RULE_VIEW,
  DefaultDeviceEventsByPERuleGrid
} from './default-device-events-by-pe-rule-grid.definition';
import {
  DEFAULT_DEVICES_COLUMNS_FOR_DEVICE_EVENTS_BY_SITE_VIEW,
  DEFAULT_EVENTS_COLUMNS_FOR_DEVICE_EVENTS_BY_SITE_VIEW,
  DefaultDeviceEventsBySiteGrid
} from './default-device-events-by-site-grid.definition';
import {
  DEFAULT_DEVICES_COLUMNS_FOR_DEVICE_EVENTS_BY_TOPIC_VIEW,
  DEFAULT_EVENTS_COLUMNS_FOR_DEVICE_EVENTS_BY_TOPIC_VIEW,
  DefaultDeviceEventsByTopicGrid
} from './default-device-events-by-topic-grid.definition';
import {
  DEFAULT_ASSET_COLUMNS_FOR_DEVICE_EVENTS_VIEW,
  DEFAULT_DEVICES_COLUMNS_FOR_DEVICE_EVENTS_VIEW,
  DEFAULT_EVENTS_COLUMNS_FOR_DEVICE_EVENTS_VIEW,
  DEFAULT_SITES_COLUMNS_FOR_DEVICE_EVENTS_VIEW,
  DefaultDeviceEventsGrid
} from './default-device-events-grid.definition';
import {
  DEFAULT_DEVICES_COLUMNS_FOR_DEVICES_VIEW,
  DEFAULT_DIAGNOSTIC_VARIABLE_COLUMNS_FOR_DEVICE_VIEW,
  DEFAULT_SITES_COLUMNS_FOR_DEVICES_VIEW,
  DEFAULT_TECHNICAL_COLUMNS_FOR_DEVICES_VIEW,
  DefaultDevicesGrid
} from './default-device-grid.definition';

import { DEFAULT_EMAIL_TEMPLATES_COLUMNS_FOR_EMAIL_TEMPLATES_VIEW, DefaultEmailTemplatesGrid } from './default-email-templates-grid.definition';
import { DEFAULT_DEVICES_COLUMNS_FOR_KERCOM_DEVICES_VIEW } from './default-kercom-device-grid.definition';
import {
  DEFAULT_GEOLOCATION_COLUMNS_FOR_SITES_VIEW,
  DEFAULT_SITES_COLUMNS_FOR_SITES_VIEW,
  DEFAULT_TECHNICAL_COLUMNS_FOR_SITES_VIEW,
  DefaultSitesGrid
} from './default-sites-grid.definition';
import {
  DEFAULT_DEVICE_COLUMNS_FOR_STOCK_SITE_DEVICES_VIEW,
  DEFAULT_TECHNICAL_COLUMNS_FOR_STOCK_SITE_DEVICES_VIEW,
  DefaultStockSiteDevicesGrid
} from './default-stock-site-devices-grid.definition';
import { DEFAULT_USERS_COLUMNS_FOR_USERS_VIEW } from './default-users-grid.definition';
import { DEFAULT_DEVICES_COLUMNS_FOR_XMQTT_DEVICES_VIEW } from './default-xmqtt-device-grid.definition';
import { I4BColumn } from './definitions';
import { I4bCellConcept } from './enums';
import { I4BColumnHeader } from './models';
import { I4BColumnOptions } from './options';

export const defaultGridsDefinitions: {
  viewType: string;
  dataLibraries: {
    libraryName: string;
    columns: I4BColumn<I4BColumnHeader, I4BColumnConfiguration, I4BColumnOptions>[];
  }[];
}[] = [
  {
    viewType: 'SITES',
    dataLibraries: [
      {
        libraryName: 'SITES',
        columns: DEFAULT_SITES_COLUMNS_FOR_SITES_VIEW
      },
      {
        libraryName: 'TAGS',
        columns: []
      },
      {
        libraryName: 'GEOLOCATION',
        columns: DEFAULT_GEOLOCATION_COLUMNS_FOR_SITES_VIEW
      },
      {
        libraryName: 'TECHNICAL',
        columns: DEFAULT_TECHNICAL_COLUMNS_FOR_SITES_VIEW
      }
    ]
  },
  {
    viewType: 'STOCK-SITE-DEVICES',
    dataLibraries: [
      {
        libraryName: 'DEVICES',
        columns: DEFAULT_DEVICE_COLUMNS_FOR_STOCK_SITE_DEVICES_VIEW
      },
      {
        libraryName: 'TECHNICAL',
        columns: DEFAULT_TECHNICAL_COLUMNS_FOR_STOCK_SITE_DEVICES_VIEW
      }
    ]
  },
  {
    viewType: 'ASSETS',
    dataLibraries: [
      {
        libraryName: 'SITES',
        columns: DEFAULT_SITES_COLUMNS_FOR_ASSETS_VIEW
      },
      {
        libraryName: 'ASSETS',
        columns: DEFAULT_ASSETS_COLUMNS_FOR_ASSETS_VIEW
      },
      {
        libraryName: 'FOLLOWED VARIABLES',
        columns: FOLLOWED_VARIABLES_COLUMNS_FOR_ASSETS_VIEW
      },
      {
        libraryName: 'GROUPED VARIABLES',
        columns: VARIABLES_GROUPED_COLUMNS_FOR_ASSETS_VIEW
      },
      {
        libraryName: 'TAGS',
        columns: []
      },
      {
        libraryName: 'TECHNICAL',
        columns: DEFAULT_TECHNICAL_COLUMNS_FOR_ASSETS_VIEW
      }
    ]
  },
  {
    viewType: 'DEVICES',
    dataLibraries: [
      {
        libraryName: 'SITES',
        columns: DEFAULT_SITES_COLUMNS_FOR_DEVICES_VIEW
      },
      {
        libraryName: 'DEVICES',
        columns: DEFAULT_DEVICES_COLUMNS_FOR_DEVICES_VIEW
      },
      {
        libraryName: 'DIAGNOSTIC VARIABLES',
        columns: DEFAULT_DIAGNOSTIC_VARIABLE_COLUMNS_FOR_DEVICE_VIEW
      },
      {
        libraryName: 'TAGS',
        columns: []
      },
      {
        libraryName: 'TECHNICAL',
        columns: DEFAULT_TECHNICAL_COLUMNS_FOR_DEVICES_VIEW
      }
    ]
  },
  {
    viewType: 'DALIA-DEVICES',
    dataLibraries: [
      {
        libraryName: 'DEVICES',
        columns: DEFAULT_DEVICES_COLUMNS_FOR_DALIA_DEVICES_VIEW
      },
      {
        libraryName: 'DIAGNOSTIC VARIABLES',
        columns: DEFAULT_DIAGNOSTIC_VARIABLE_COLUMNS_FOR_DALIA_DEVICE_VIEW
      }
    ]
  },
  {
    viewType: 'XMQTT-DEVICES',
    dataLibraries: [
      {
        libraryName: 'DEVICES',
        columns: DEFAULT_DEVICES_COLUMNS_FOR_XMQTT_DEVICES_VIEW
      }
    ]
  },
  {
    viewType: 'KERCOM-DEVICES',
    dataLibraries: [
      {
        libraryName: 'DEVICES',
        columns: DEFAULT_DEVICES_COLUMNS_FOR_KERCOM_DEVICES_VIEW
      }
    ]
  },
  {
    viewType: 'DALIA-FIRMWARES',
    dataLibraries: [
      {
        libraryName: 'FIRMWARES',
        columns: DEFAULT_FIRMWARES_COLUMNS_FOR_DALIA_FIRMWARES_VIEW
      }
    ]
  },
  {
    viewType: 'DALIA-SENSORS',
    dataLibraries: [
      {
        libraryName: 'SENSORS',
        columns: DEFAULT_SENSORS_COLUMNS_FOR_DALIA_SENSORS_VIEW
      }
    ]
  },
  {
    viewType: 'DALIA-DEVICE-TEMPLATES',
    dataLibraries: [
      {
        libraryName: 'DEVICE TEMPLATES',
        columns: DEFAULT_DEVICE_TEMPLATES_COLUMNS_FOR_DALIA_DEVICE_TEMPLATES_VIEW
      }
    ]
  },
  {
    viewType: 'DEVICE-EVENTS',
    dataLibraries: [
      {
        libraryName: 'SITES',
        columns: DEFAULT_SITES_COLUMNS_FOR_DEVICE_EVENTS_VIEW
      },
      {
        libraryName: 'ASSETS',
        columns: DEFAULT_ASSET_COLUMNS_FOR_DEVICE_EVENTS_VIEW
      },
      {
        libraryName: 'DEVICES',
        columns: DEFAULT_DEVICES_COLUMNS_FOR_DEVICE_EVENTS_VIEW
      },
      {
        libraryName: 'EVENTS',
        columns: DEFAULT_EVENTS_COLUMNS_FOR_DEVICE_EVENTS_VIEW
      },
      {
        libraryName: 'TAGS',
        columns: []
      }
    ]
  },
  {
    viewType: 'ASSET-EVENTS',
    dataLibraries: [
      {
        libraryName: 'SITES',
        columns: DEFAULT_SITES_COLUMNS_FOR_ASSET_EVENTS_VIEW
      },
      {
        libraryName: 'ASSETS',
        columns: DEFAULT_ASSETS_COLUMNS_FOR_ASSET_EVENTS_VIEW
      },
      {
        libraryName: 'DEVICES',
        columns: DEFAULT_DEVICES_COLUMNS_FOR_ASSETS_EVENTS_VIEW
      },
      {
        libraryName: 'EVENTS',
        columns: DEFAULT_EVENTS_COLUMNS_FOR_ASSET_EVENTS_VIEW
      },
      {
        libraryName: 'TAGS',
        columns: []
      }
    ]
  },
  {
    viewType: 'EMAIL-TEMPLATES',
    dataLibraries: [
      {
        libraryName: 'EMAIL TEMPLATES',
        columns: DEFAULT_EMAIL_TEMPLATES_COLUMNS_FOR_EMAIL_TEMPLATES_VIEW
      }
    ]
  },
  {
    viewType: 'CONNECTORS',
    dataLibraries: [
      {
        libraryName: 'CONNECTORS',
        columns: DEFAULT_CONNECTORS_COLUMNS_FOR_CONNECTORS_VIEW
      }
    ]
  },
  {
    viewType: 'ASSET-TEMPLATES',
    dataLibraries: [
      {
        libraryName: 'ASSET TEMPLATES',
        columns: DEFAULT_ASSET_TEMPLATES_COLUMNS_FOR_ASSET_TEMPLATES_VIEW
      }
    ]
  },
  {
    viewType: 'ACTIVE-ASSET-EVENTS-POPUP',
    dataLibraries: [
      {
        libraryName: 'SITES',
        columns: DEFAULT_SITES_COLUMNS_FOR_ASSET_EVENTS_VIEW
      },
      {
        libraryName: 'ASSETS',
        columns: DEFAULT_ASSETS_COLUMNS_FOR_ASSET_EVENTS_VIEW
      },
      {
        libraryName: 'DEVICES',
        columns: DEFAULT_DEVICES_COLUMNS_FOR_ASSETS_EVENTS_VIEW
      },
      {
        libraryName: 'EVENTS',
        columns: DEFAULT_EVENTS_COLUMNS_FOR_ASSET_EVENTS_VIEW
      },
      {
        libraryName: 'TAGS',
        columns: []
      }
    ]
  },
  {
    viewType: 'ASSET-EVENTS-BY-PE-RULE',
    dataLibraries: [
      {
        libraryName: 'ASSETS',
        columns: DEFAULT_ASSETS_COLUMNS_FOR_ASSET_EVENTS_BY_PE_RULE_VIEW
      },
      {
        libraryName: 'EVENTS',
        columns: DEFAULT_EVENTS_COLUMNS_FOR_ASSET_EVENTS_BY_PE_RULE_VIEW
      },
      {
        libraryName: 'TAGS',
        columns: []
      }
    ]
  },
  {
    viewType: 'DEVICE-EVENTS-BY-PE-RULE',
    dataLibraries: [
      {
        libraryName: 'DEVICES',
        columns: DEFAULT_DEVICES_COLUMNS_FOR_DEVICE_EVENTS_BY_PE_RULE_VIEW
      },
      {
        libraryName: 'EVENTS',
        columns: DEFAULT_EVENTS_COLUMNS_FOR_DEVICE_EVENTS_BY_PE_RULE_VIEW
      },
      {
        libraryName: 'TAGS',
        columns: []
      }
    ]
  },
  {
    viewType: 'ASSET-EVENTS-BY-SITE',
    dataLibraries: [
      {
        libraryName: 'ASSETS',
        columns: DEFAULT_ASSETS_COLUMNS_FOR_ASSET_EVENTS_BY_SITE_VIEW
      },
      {
        libraryName: 'EVENTS',
        columns: DEFAULT_EVENTS_COLUMNS_FOR_ASSET_EVENTS_BY_SITE_VIEW
      },
      {
        libraryName: 'TAGS',
        columns: []
      }
    ]
  },
  {
    viewType: 'DEVICE-EVENTS-BY-SITE',
    dataLibraries: [
      {
        libraryName: 'DEVICES',
        columns: DEFAULT_DEVICES_COLUMNS_FOR_DEVICE_EVENTS_BY_SITE_VIEW
      },
      {
        libraryName: 'EVENTS',
        columns: DEFAULT_EVENTS_COLUMNS_FOR_DEVICE_EVENTS_BY_SITE_VIEW
      },
      {
        libraryName: 'TAGS',
        columns: []
      }
    ]
  },
  {
    viewType: 'ASSET-EVENTS-BY-ASSET',
    dataLibraries: [
      {
        libraryName: 'ASSETS',
        columns: DEFAULT_ASSETS_COLUMNS_FOR_ASSET_EVENTS_BY_ASSET_VIEW
      },
      {
        libraryName: 'EVENTS',
        columns: DEFAULT_EVENTS_COLUMNS_FOR_ASSET_EVENTS_BY_ASSET_VIEW
      },
      {
        libraryName: 'TAGS',
        columns: []
      }
    ]
  },
  {
    viewType: 'DEVICE-EVENTS-BY-DEVICE',
    dataLibraries: [
      {
        libraryName: 'DEVICES',
        columns: DEFAULT_DEVICES_COLUMNS_FOR_DEVICE_EVENTS_BY_DEVICE_VIEW
      },
      {
        libraryName: 'EVENTS',
        columns: DEFAULT_EVENTS_COLUMNS_FOR_DEVICE_EVENTS_BY_DEVICE_VIEW
      },
      {
        libraryName: 'TAGS',
        columns: []
      }
    ]
  },
  {
    viewType: 'ASSET-EVENTS-BY-TOPIC',
    dataLibraries: [
      {
        libraryName: 'ASSETS',
        columns: DEFAULT_ASSETS_COLUMNS_FOR_ASSET_EVENTS_BY_TOPIC_VIEW
      },
      {
        libraryName: 'EVENTS',
        columns: DEFAULT_EVENTS_COLUMNS_FOR_ASSET_EVENTS_BY_TOPIC_VIEW
      },
      {
        libraryName: 'TAGS',
        columns: []
      }
    ]
  },
  {
    viewType: 'DEVICE-EVENTS-BY-TOPIC',
    dataLibraries: [
      {
        libraryName: 'DEVICES',
        columns: DEFAULT_DEVICES_COLUMNS_FOR_DEVICE_EVENTS_BY_TOPIC_VIEW
      },
      {
        libraryName: 'EVENTS',
        columns: DEFAULT_EVENTS_COLUMNS_FOR_DEVICE_EVENTS_BY_TOPIC_VIEW
      },
      {
        libraryName: 'TAGS',
        columns: []
      }
    ]
  },
  {
    viewType: 'USERS',
    dataLibraries: [
      {
        libraryName: 'USERS',
        columns: DEFAULT_USERS_COLUMNS_FOR_USERS_VIEW
      }
    ]
  }
];

export const getDefaultColumnsByConcept = (concept: I4bCellConcept | string): I4BColumn<I4BColumnHeader, I4BColumnConfiguration, I4BColumnOptions>[] =>
  defaultGridsDefinitions
    .filter((defaultGrid) => defaultGrid.viewType.toLowerCase() === concept.toLowerCase())[0]
    ?.dataLibraries.reduce((acc: any, value) => {
      acc = [...acc, ...value.columns.filter((col) => col.configuration.isDefault)];
      return acc;
    }, [])
    .sort((colA: any, colB: any) => (colA.options.order > colB.options.order ? 1 : -1)) ?? [];

export const getAllColumnsByConcept = (concept: I4bCellConcept | string): I4BColumn<I4BColumnHeader, I4BColumnConfiguration, I4BColumnOptions>[] =>
  defaultGridsDefinitions
    .filter((defaultGrid) => defaultGrid.viewType.toLowerCase() === concept.toLowerCase())[0]
    ?.dataLibraries.reduce((acc: any, value) => {
      acc = [...acc, ...value.columns];
      return acc;
    }, []) ?? [];

export const defaultSitesGrid: DefaultSitesGrid = new DefaultSitesGrid();
export const defaultStockSiteDevicesGrid: DefaultStockSiteDevicesGrid = new DefaultStockSiteDevicesGrid();
export const defaultAssetsGrid: DefaultAssetsGrid = new DefaultAssetsGrid();
export const defaultDevicesGrid: DefaultDevicesGrid = new DefaultDevicesGrid();
export const defaultDeviceEventsGrid: DefaultDeviceEventsGrid = new DefaultDeviceEventsGrid();
export const defaultAssetEventsGrid: DefaultAssetEventsGrid = new DefaultAssetEventsGrid();
export const defaultEmailTemplatesGrid: DefaultEmailTemplatesGrid = new DefaultEmailTemplatesGrid();
export const defaultConnectorsGrid: DefaultConnectorsGrid = new DefaultConnectorsGrid();
export const defaultActiveAssetEventsPopupGrid: DefaultActiveAssetEventsPopupGrid = new DefaultActiveAssetEventsPopupGrid();
export const defaultAssetEventsByPERuleGrid: DefaultAssetEventsByPERuleGrid = new DefaultAssetEventsByPERuleGrid();
export const defaultDeviceEventsByPERuleGrid: DefaultDeviceEventsByPERuleGrid = new DefaultDeviceEventsByPERuleGrid();
export const defaultAssetTemplatesGrid: DefaultAssetTemplatesGrid = new DefaultAssetTemplatesGrid();
export const defaultAssetEventsByTopicGrid: DefaultAssetEventsByTopicGrid = new DefaultAssetEventsByTopicGrid();
export const defaultDeviceEventsByTopicGrid: DefaultDeviceEventsByTopicGrid = new DefaultDeviceEventsByTopicGrid();
export const defaultAssetEventsBySiteGrid: DefaultAssetEventsBySiteGrid = new DefaultAssetEventsBySiteGrid();
export const defaultDeviceEventsBySiteGrid: DefaultDeviceEventsBySiteGrid = new DefaultDeviceEventsBySiteGrid();
export const defaultAssetEventsByAssetGrid: DefaultAssetEventsByAssetGrid = new DefaultAssetEventsByAssetGrid();
export const defaultDeviceEventsByDeviceGrid: DefaultDeviceEventsByDeviceGrid = new DefaultDeviceEventsByDeviceGrid();
